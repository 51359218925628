<template>
    <animation name="fadeInUpShort">
        <home-section>
            <h1>{{ $t('home.partners') }}</h1>

            <ul class="partners">
                <li>
                    <img src="~@/assets/images/logo-csfail.png" alt="csfail" />
                </li>

                <li>
                    <img src="~@/assets/images/logo-ggdrop.png" alt="ggdrop" />
                </li>
            </ul>
        </home-section>
    </animation>
</template>

<script>
    import Animation from '@/components/Animation';
    import HomeSection from '@/components/home/HomeSection';

    export default {
        name: 'Partners',
        components: {
            Animation,
            HomeSection,
        },
    };
</script>

<style lang="scss" scoped>
    .partners {
        display: grid;
        grid-gap: 2rem;
        grid-auto-rows: 1fr;

        li {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        img {
            max-width: 16.5rem;
            max-height: 4.5rem;
        }
    }

    @media screen and (min-width: 30em) {
        .partners {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    @media screen and (min-width: 40em) {
        .partners img {
            max-width: 23rem;
            max-height: 6rem;
        }
    }

    @media screen and (min-width: 64em) {
        .partners {
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 3rem;
        }
    }

    @media screen and (min-width: 120em) {
        .partners img {
            max-width: 29rem;
            max-height: 8rem;
        }
    }
</style>
