<template>
    <animation name="fadeInUpShort">
        <home-section class="games__wrapper">
            <h1 v-html="$t('home.games.title')" />

            <div class="games">
                <img src="~@/assets/images/logo-outlined-bg.png" alt="Background logo" />

                <div class="games__characters">
                    <img src="~@/assets/images/characters.png" alt="characters" />

                    <div class="games__character-csgo">
                        <img src="~@/assets/images/character-csgo.png" alt="CS:GO character" />

                        <img src="~@/assets/images/label-csgo.png" alt="CS:GO label" />

                        <p>{{ $t('home.games.csgo') }}</p>
                    </div>

                    <div class="games__character-dota">
                        <img src="~@/assets/images/character-dota.png" alt="Dota 2 character" />

                        <img src="~@/assets/images/label-dota.png" alt="Dota 2 label" />

                        <p>{{ $t('home.games.dota') }}</p>
                    </div>
                </div>
            </div>
        </home-section>
    </animation>
</template>

<script>
    import Animation from '@/components/Animation';
    import HomeSection from '@/components/home/HomeSection';

    export default {
        name: 'Games',
        components: {
            Animation,
            HomeSection,
        },
    };
</script>

<style lang="scss" scoped>
    .games__wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .games {
        position: relative;
        width: 44rem;

        > img {
            position: absolute;
            top: -2.5rem;
            left: 0;
            width: 100%;
        }
    }

    .games__characters {
        position: relative;
        display: flex;
        justify-content: center;
        width: 100%;
        pointer-events: none;

        > img {
            width: 100%;
            transition: opacity 0.3s;
        }

        &:hover img {
            opacity: 0.3;
        }
    }

    .games__character-csgo,
    .games__character-dota {
        position: absolute;
        top: 0;
        width: 50%;
        height: 100%;
        pointer-events: none;

        * {
            pointer-events: none;
            transition: opacity 0.3s;
        }

        img {
            &:first-of-type {
                position: absolute;
                left: inherit;
                right: inherit;
                height: 100%;
                opacity: 0;
            }

            &:last-of-type {
                display: none;
                position: absolute;
                bottom: 17.44%;
                width: 5.33%;
            }
        }

        p {
            position: absolute;
            bottom: 17.44%;
            width: max-content;
            max-width: 47rem;
            padding: 3rem;
            border-radius: 1rem;
            background: rgba(60, 65, 109, 0.7);
            backdrop-filter: blur(1.45rem);
            color: #d1ddff;
            font-family: 'Gotham Book', sans-serif;
            font-size: 1.5rem;
            font-weight: 325;
            line-height: 1.6;
            opacity: 0;
        }

        &:hover * {
            opacity: 1 !important;
        }
    }

    .games__character-csgo {
        left: 0;

        img:last-of-type {
            left: 11.67%;
        }

        p {
            left: 21.71%;
        }
    }

    .games__character-dota {
        right: 0;

        img:last-of-type {
            right: 11.67%;
        }

        p {
            right: 21.71%;
        }
    }

    @media screen and (min-width: 30em) {
        .games {
            width: 100%;
        }

        .games__character-csgo,
        .games__character-dota {
            img:last-of-type {
                display: block;
            }
        }
    }

    @media screen and (min-width: 40em) {
        .games > img {
            top: -4rem;
        }
    }

    @media screen and (min-width: 64em) {
        .games > img {
            top: -5rem;
        }

        .games__characters,
        .games__character-csgo,
        .games__character-dota {
            pointer-events: auto;
        }
    }
</style>
