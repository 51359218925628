<template>
    <animation tab="section" name="fadeInUpShort" :delay-on-render="2">
        <home-section>
            <h1>{{ $t('home.why.title') }}</h1>

            <ul class="reasons">
                <li>
                    <h2 v-html="$t('home.why.price.title')" />

                    <p>{{ $t('home.why.price.description') }}</p>

                    <img src="~@/assets/images/skins-trade.png" alt="skins-trade" />
                </li>

                <li>
                    <h2 v-html="$t('home.why.money.title')" />

                    <p>{{ $t('home.why.money.description') }}</p>
                </li>

                <li>
                    <h2 v-html="$t('home.why.security.title')" />

                    <p>{{ $t('home.why.security.description') }}</p>
                </li>

                <li>
                    <h2 v-html="$t('home.why.support.title')" />

                    <p>{{ $t('home.why.support.description') }}</p>

                    <img src="~@/assets/images/support-girl.png" alt="support-girl" />
                </li>
            </ul>
        </home-section>
    </animation>
</template>

<script>
    import Animation from '@/components/Animation';
    import HomeSection from '@/components/home/HomeSection';

    export default {
        name: 'Reasons',
        components: {
            Animation,
            HomeSection,
        },
    };
</script>

<style lang="scss" scoped>
    .reasons {
        li {
            position: relative;
            padding: 2rem;
            border-radius: 2.4rem;
            background: linear-gradient(231deg, rgba(28, 32, 52, 0.3) -9.38%, rgba(15, 20, 37, 0.3) 100%);

            &:not(:last-child) {
                margin-bottom: 2rem;
            }
        }

        h2 {
            color: #dce3fd;
            font-family: 'Gotham Bold', sans-serif;
            font-size: 1.6rem;
            font-weight: 400;
            line-height: 1.3;
            text-align: center;

            ::v-deep br {
                display: none;
            }
        }

        p {
            max-width: 36rem;
            margin: 1rem auto 0;
            color: #97a0bf;
            font-family: 'Gotham Book', sans-serif;
            font-size: 1.2rem;
            font-weight: 325;
            line-height: 1.5;
            text-align: center;
        }

        img {
            display: none;
        }
    }

    @media screen and (min-width: 40em) {
        .reasons {
            li {
                padding: 3rem;
            }

            h2 {
                font-size: 1.8rem;
            }

            p {
                margin-top: 1.5rem;
                font-size: 1.4rem;
            }
        }
    }

    @media screen and (min-width: 48em) {
        .reasons {
            display: grid;
            grid-gap: 2rem;
            grid-template-columns: 1fr 1fr;
            grid-auto-rows: 1fr;

            li:not(:last-child) {
                margin: 0;
            }
        }
    }

    @media screen and (min-width: 64em) {
        .reasons {
            grid-gap: 3rem;

            li {
                height: 26rem;
                padding: 4rem;

                &:first-child,
                &:last-child {
                    grid-column-start: 1;
                    grid-column-end: 3;
                }

                &:first-child img {
                    height: 100%;
                }

                &:last-child img {
                    height: 109%;
                }
            }

            h2 {
                font-size: 2rem;
                text-align: left;

                ::v-deep br {
                    display: block;
                }
            }

            p {
                max-width: 39rem;
                margin-left: 0;
                font-size: 1.4rem;
                text-align: left;
            }

            img {
                position: absolute;
                bottom: 0;
                right: 0;
                display: block;
            }
        }
    }

    @media screen and (min-width: 80em) {
        .reasons {
            display: flex;
            flex-wrap: wrap;

            li {
                width: calc(30% - 1.5rem);

                &:first-child,
                &:last-child {
                    width: calc(70% - 1.5rem);
                }
            }
        }
    }

    @media screen and (min-width: 120em) {
        .reasons {
            li {
                height: 29rem;
            }

            h2 {
                font-size: 2.2rem;
            }

            p {
                font-size: 1.6rem;
            }
        }
    }
</style>
