<template>
    <div class="section">
        <slot />
    </div>
</template>

<script>
    export default {
        name: 'HomeSection',
    };
</script>

<style lang="scss" scoped>
    .section {
        margin-top: 7.5rem;

        ::v-deep h1 {
            margin: 0 auto 2.5rem;
            color: #ffffff;
            font-family: 'Gotham Ultra', sans-serif;
            font-size: 2.8rem;
            line-height: 1.3;
            text-align: center;
        }
    }

    @media screen and (min-width: 40em) {
        .section {
            margin-top: 12.5rem;

            ::v-deep h1 {
                font-size: 3.8rem;
                margin-bottom: 4rem;
            }
        }
    }

    @media screen and (min-width: 64em) {
        .section {
            margin-top: 15rem;

            ::v-deep h1 {
                font-size: 4.2rem;
                margin-bottom: 5rem;
            }
        }
    }

    @media screen and (min-width: 120em) {
        .section::v-deep h1 {
            font-size: 4.8rem;
        }
    }
</style>
