<template>
    <component
        ref="container"
        :is="tag"
        :class="['animation__container', 'animate__animated', isInViewport ? `${animationName} animation__container_in-view` : '']"
        :style="{ animationDuration: `${duration}s`, animationDelay: `${animation.delay}s`, animationTimingFunction: timingFunction }"
    >
        <slot />
    </component>
</template>

<script>
    export default {
        name: 'Animation',
        props: {
            delay: {
                type: Number,
                default: 0,
            },
            delayOnRender: {
                type: Number,
                default: 0,
            },
            duration: {
                type: Number,
                default: 2,
            },
            name: {
                type: [String, Array],
                required: true,
            },
            tag: {
                type: String,
                default: 'div',
            },
            timingFunction: {
                type: String,
                default: 'ease',
                validator: value => ['ease', 'ease-in', 'ease-out', 'ease-in-out', 'linear'].includes(value),
            },
        },
        computed: {
            animationName() {
                return (Array.isArray(this.name) ? this.name : [this.name]).map(name => `animate__${name}`);
            },
            isInViewport() {
                return !this.viewportIO;
            },
        },
        data() {
            return {
                animation: {
                    delay: this.delay,
                },
                isObserved: false,
                viewportIO: null,
            };
        },
        mounted() {
            this.viewportIO = new IntersectionObserver(
                entries => {
                    entries.forEach(entry => {
                        if (entry.isIntersecting || entry.boundingClientRect.y < entry.intersectionRect.y) {
                            if (!this.isObserved && this.delayOnRender) {
                                this.animation.delay = this.delayOnRender;
                            }

                            this.viewportIO.unobserve(entry.target);
                            this.viewportIO = null;
                        }

                        this.isObserved = true;
                    });
                },
                { threshold: 0.2 }
            );

            this.viewportIO.observe(this.$refs.container);
        },
    };
</script>

<style lang="scss" scoped>
    @import '~animate.css/animate.min.css';

    @keyframes fadeInUpShort {
        0% {
            opacity: 0;
            transform: translate3d(0, 6rem, 0);
        }

        50% {
            transform: translate3d(0, 0, 0);
        }

        100% {
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    }

    .animation__container {
        opacity: 0;
        will-change: opacity, transform;
    }

    .animate__fadeInUpShort {
        animation-name: fadeInUpShort;
    }
</style>
