<template>
    <scroll-container ref="scroll">
        <div class="home">
            <animation class="home__section home__header" name="fadeInDown" :delay="1.5">
                <auth-header @scrollTo="scrollToSection" />
            </animation>

            <div class="home__inner">
                <hero class="home__section" />

                <reasons class="home__section" ref="why" />

                <games class="home__section" />

                <partners class="home__section" ref="partners" />

                <promo class="home__section" />

                <animation class="home__section home__footer" name="fadeInUp">
                    <auth-footer />
                </animation>
            </div>
        </div>
    </scroll-container>
</template>

<script>
    import Animation from '@/components/Animation';
    import AuthFooter from '@/pages/layout/AuthFooter';
    import AuthHeader from '@/pages/layout/AuthHeader';
    import Games from '@/components/home/Games';
    import Hero from '@/components/home/Hero';
    import Partners from '@/components/home/Partners';
    import Promo from '@/components/home/Promo';
    import Reasons from '@/components/home/Reasons';
    import ScrollContainer from '@/components/ScrollContainer';

    export default {
        name: 'Home',
        components: {
            Animation,
            AuthFooter,
            AuthHeader,
            Games,
            Hero,
            Partners,
            Promo,
            Reasons,
            ScrollContainer,
        },
        methods: {
            scrollToSection(sectionName) {
                if (this.$refs[sectionName]) {
                    const section = this.$refs[sectionName].$el;
                    const offsetY = -parseInt(window.getComputedStyle(section).marginTop) / 2;
                    this.$refs.scroll.bs.scrollToElement(section, 2000, 0, offsetY);
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    .home {
        position: relative;
        background-color: #050b1c;
        overflow: hidden;
    }

    .home__inner {
        position: inherit;
        max-width: 120rem;
        margin: 0 auto;
        padding: 0 1.5rem 1.5rem;
        box-sizing: content-box;

        &:before,
        &:after {
            content: '';
            position: absolute;
            right: 50%;
            width: 100rem;
            height: 100rem;
            border-radius: 100%;
            transform: translate(50%, 0);
            z-index: 0;
        }

        &:before {
            top: -31.5rem;
            background: radial-gradient(50% 50% at 50% 50%, #131537 0%, #070d21 64.58%, #050b1c 100%);
        }

        &:after {
            bottom: -50rem;
            background: radial-gradient(50% 50% at 50% 50%, #0f172e 0%, #050b1c 100%);
        }
    }

    .home__section {
        position: inherit;
        z-index: 1;
    }

    .home__header {
        z-index: 2;
    }

    .home__footer {
        margin-top: 7.5rem;
    }

    @media screen and (min-width: 64em) {
        .home__inner {
            padding: 0 4rem 4rem;

            &:before {
                right: -23.7rem;
                width: 130rem;
                height: 130rem;
                transform: unset;
            }

            &:after {
                bottom: -60rem;
                width: 120rem;
                height: 120rem;
            }
        }
    }
</style>
